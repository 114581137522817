import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import './Stats.css';

const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

const Stats = () => {
    const [stats, setStats] = useState({
        total_matches: 0,
        wins: 0,
        losses: 0
    });

    const [statsByType, setStatsByType] = useState([]);
    const [statsByOdds, setStatsByOdds] = useState([]);

    useEffect(() => {
        fetchStats();
        fetchStatsByType();
        fetchStatsByOdds();
    }, []);

    const fetchStats = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/stats`);
            const data = response.data;
            setStats({
                total_matches: parseInt(data.total_matches, 10),
                wins: parseInt(data.wins, 10),
                losses: parseInt(data.losses, 10)
            });
        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    };

    const fetchStatsByType = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/stats/by-type`);
            setStatsByType(response.data);
        } catch (error) {
            console.error('Error fetching stats by type:', error);
        }
    };

    const fetchStatsByOdds = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/stats/by-odds`);
            setStatsByOdds(response.data);
        } catch (error) {
            console.error('Error fetching stats by odds:', error);
        }
    };

    const calculateWinPercentage = (wins, total) => {
        return total > 0 ? ((wins / total) * 100).toFixed(2) : '0.00';
    };

    return (
        <Container className="stats-container">
            <Card className="stat-card">
                <CardContent>
                    <Typography variant="h4" component="div">
                        Statistiques Globales
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className="stats-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Total des matchs</TableCell>
                                    <TableCell>Gagnés</TableCell>
                                    <TableCell>Perdus</TableCell>
                                    <TableCell>% Gagnés</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{stats.total_matches}</TableCell>
                                    <TableCell>{stats.wins}</TableCell>
                                    <TableCell>{stats.losses}</TableCell>
                                    <TableCell>{calculateWinPercentage(stats.wins, stats.total_matches)}%</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <Card className="stat-card">
                <CardContent>
                    <Typography variant="h4" component="div">
                        Statistiques par Type de Pari
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className="stats-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type de Pari</TableCell>
                                    <TableCell>Total des matchs</TableCell>
                                    <TableCell>Gagnés</TableCell>
                                    <TableCell>Perdus</TableCell>
                                    <TableCell>% Gagnés</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statsByType.map((stat, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{stat.type}</TableCell>
                                        <TableCell>{stat.total_matches}</TableCell>
                                        <TableCell>{stat.wins}</TableCell>
                                        <TableCell>{stat.losses}</TableCell>
                                        <TableCell>{calculateWinPercentage(stat.wins, stat.total_matches)}%</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <Card className="stat-card">
                <CardContent>
                    <Typography variant="h4" component="div">
                        Statistiques par Intervalle de Cote
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className="stats-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sport</TableCell>
                                    <TableCell>Type de Pari</TableCell>
                                    <TableCell>Intervalle de Cote</TableCell>
                                    <TableCell>Total des matchs</TableCell>
                                    <TableCell>Gagnés</TableCell>
                                    <TableCell>Perdus</TableCell>
                                    <TableCell>% Gagnés</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statsByOdds.map((stat, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{stat.sport}</TableCell>
                                        <TableCell>{stat.type}</TableCell>
                                        <TableCell>{stat.odds_range}</TableCell>
                                        <TableCell>{stat.total_matches}</TableCell>
                                        <TableCell>{stat.wins}</TableCell>
                                        <TableCell>{stat.losses}</TableCell>
                                        <TableCell>{calculateWinPercentage(stat.wins, stat.total_matches)}%</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Stats;