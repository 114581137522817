import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Select, MenuItem } from '@mui/material';

const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

const AddTeams = () => {
    const [championships, setChampionships] = useState([]);
    const [teams, setTeams] = useState([]);
    const [selectedChampionship, setSelectedChampionship] = useState('');
    const [newChampionshipName, setNewChampionshipName] = useState('');
    const [newChampionshipCountry, setNewChampionshipCountry] = useState('');
    const [newTeamName, setNewTeamName] = useState('');
    const [editingTeam, setEditingTeam] = useState(null);
    const [filterChampionship, setFilterChampionship] = useState('');
    const [filterCountry, setFilterCountry] = useState('');

    useEffect(() => {
        fetchChampionships();
        fetchTeams();
    }, []);

    const fetchChampionships = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/teams/championships`);
            setChampionships(response.data);
        } catch (error) {
            console.error('Error fetching championships:', error);
        }
    };

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/teams`);
            setTeams(response.data);
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const handleAddTeams = async () => {
        const teamsArray = newTeamName.split('\n').map(team => ({
            name: team.trim(),
            championship: selectedChampionship
        }));

        try {
            await axios.post(`${API_URL}/api/teams/add`, { teams: teamsArray });
            alert('Teams added successfully');
            setNewTeamName('');
            fetchTeams(); // Mettre à jour les équipes après l'ajout
        } catch (error) {
            console.error('Error adding teams:', error);
            alert('Failed to add teams');
        }
    };

    const handleAddChampionship = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/teams/championships/add`, {
                name: newChampionshipName,
                country: newChampionshipCountry
            });
            alert('Championship added successfully');
            setNewChampionshipName('');
            setNewChampionshipCountry('');
            fetchChampionships(); // Mettre à jour les championnats après l'ajout
        } catch (error) {
            console.error('Error adding championship:', error);
            alert('Failed to add championship');
        }
    };

    const handleEditTeam = (team) => {
        setEditingTeam(team);
        setNewTeamName(team.name);
        setSelectedChampionship(team.championship);
    };

    const handleUpdateTeam = async () => {
        try {
            await axios.put(`${API_URL}/api/teams/${editingTeam.id}`, {
                name: newTeamName,
                championship: selectedChampionship
            });
            alert('Team updated successfully');
            setEditingTeam(null);
            setNewTeamName('');
            setSelectedChampionship('');
            fetchTeams(); // Mettre à jour les équipes après la modification
        } catch (error) {
            console.error('Error updating team:', error);
            alert('Failed to update team');
        }
    };

    const handleDeleteTeam = async (teamId) => {
        try {
            await axios.delete(`${API_URL}/api/teams/delete/${teamId}`);
            alert('Team deleted successfully');
            fetchTeams(); // Mettre à jour les équipes après la suppression
        } catch (error) {
            console.error('Error deleting team:', error);
            alert('Failed to delete team');
        }
    };

    const filteredTeams = teams.filter(team => {
        return (
            (filterChampionship === '' || team.championship === filterChampionship) &&
            (filterCountry === '' || team.country === filterCountry)
        );
    });

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" component="div">
                                Ajouter des équipes
                            </Typography>
                            <div>
                                <Typography variant="h6" component="div">
                                    Championnat:
                                </Typography>
                                <Select
                                    value={selectedChampionship}
                                    onChange={(e) => setSelectedChampionship(e.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value="">Sélectionner un championnat</MenuItem>
                                    {championships.map((championship, index) => (
                                        <MenuItem key={index} value={championship.name}>
                                            {championship.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <Typography variant="h6" component="div">
                                    Équipes (une par ligne):
                                </Typography>
                                <TextField
                                    value={newTeamName}
                                    onChange={(e) => setNewTeamName(e.target.value)}
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            </div>
                            {editingTeam ? (
                                <Button onClick={handleUpdateTeam} variant="contained" color="primary">
                                    Mettre à jour l'équipe
                                </Button>
                            ) : (
                                <Button onClick={handleAddTeams} variant="contained" color="primary">
                                    Ajouter les équipes
                                </Button>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" component="div">
                                Ajouter un championnat
                            </Typography>
                            <div>
                                <Typography variant="h6" component="div">
                                    Nom du championnat:
                                </Typography>
                                <TextField
                                    value={newChampionshipName}
                                    onChange={(e) => setNewChampionshipName(e.target.value)}
                                    fullWidth
                                />
                            </div>
                            <div>
                                <Typography variant="h6" component="div">
                                    Pays:
                                </Typography>
                                <TextField
                                    value={newChampionshipCountry}
                                    onChange={(e) => setNewChampionshipCountry(e.target.value)}
                                    fullWidth
                                />
                            </div>
                            <Button onClick={handleAddChampionship} variant="contained" color="primary">
                                Ajouter le championnat
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" component="div">
                                Équipes existantes
                            </Typography>
                            <div>
                                <Typography variant="h6" component="div">
                                    Filtrer par championnat:
                                </Typography>
                                <Select
                                    value={filterChampionship}
                                    onChange={(e) => setFilterChampionship(e.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value="">Tous les championnats</MenuItem>
                                    {championships.map((championship, index) => (
                                        <MenuItem key={index} value={championship.name}>
                                            {championship.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <Typography variant="h6" component="div">
                                    Filtrer par pays:
                                </Typography>
                                <Select
                                    value={filterCountry}
                                    onChange={(e) => setFilterCountry(e.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value="">Tous les pays</MenuItem>
                                    {championships.map((championship, index) => (
                                        <MenuItem key={index} value={championship.country}>
                                            {championship.country}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nom de l'équipe</TableCell>
                                            <TableCell>Championnat</TableCell>
                                            <TableCell>Pays</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredTeams.map((team) => (
                                            <TableRow key={team.id}>
                                                <TableCell>{team.name}</TableCell>
                                                <TableCell>{team.championship}</TableCell>
                                                <TableCell>{team.country}</TableCell>
                                                <TableCell>
                                                    <Button onClick={() => handleEditTeam(team)} variant="contained" color="primary">
                                                        Éditer
                                                    </Button>
                                                    <Button onClick={() => handleDeleteTeam(team.id)} variant="contained" color="secondary">
                                                        Supprimer
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AddTeams;