import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa'; // Import edit icon
import './SeriesManager.css';

const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

const SeriesManager = () => {
    const [series, setSeries] = useState([]);
    const [filteredSeries, setFilteredSeries] = useState([]);
    const [filter, setFilter] = useState(localStorage.getItem('filter') || 'open');
    const [seriesTypeFilter, setSeriesTypeFilter] = useState(localStorage.getItem('seriesTypeFilter') || 'all');
    const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');
    const [sportFilter, setSportFilter] = useState(localStorage.getItem('sportFilter') || 'all');
    const [newSport, setNewSport] = useState('');
    const [newSeriesType, setNewSeriesType] = useState('');
    const [seriesCount, setSeriesCount] = useState(1);
    const [previewNames, setPreviewNames] = useState([]);
    const [showZeroEnCours, setShowZeroEnCours] = useState(localStorage.getItem('showZeroEnCours') === 'true');
    const [showNonZeroEnCours, setShowNonZeroEnCours] = useState(localStorage.getItem('showNonZeroEnCours') === 'true');
    const [showModal, setShowModal] = useState(false);
    const [editingSeriesId, setEditingSeriesId] = useState(null);  // Track the series being edited
    const [editingSeriesName, setEditingSeriesName] = useState('');  // Track the new name during edit

    useEffect(() => {
        fetchSeries();
    }, []);

    useEffect(() => {
        applyFilter();
    }, [series, filter, seriesTypeFilter, searchTerm, sportFilter, showZeroEnCours, showNonZeroEnCours]);

    useEffect(() => {
        generatePreview();
    }, [seriesCount, newSeriesType]);

    const fetchSeries = async () => {
        try {
            const seriesResponse = await axios.get(`${API_URL}/api/series`);
            const seriesWithDetails = seriesResponse.data.map(series => {
                const currentExpenses = calculateCurrentExpenses(series.bets || []);
                const totalExpenses = calculateTotalExpenses(series.bets || []);
                const lastTeamPlayed = getLastTeamPlayed(series.bets || []);
                const lastBetProfit = getLastBetProfit(series.bets || []);
                return {
                    ...series,
                    currentExpenses,
                    totalExpenses,
                    lastTeamPlayed,
                    lastBetProfit
                };
            });
            setSeries(seriesWithDetails);
        } catch (error) {
            console.error('Error fetching series:', error);
            alert('Error fetching series: ' + error.message);
        }
    };

    const calculateCurrentExpenses = (bets) => {
        return bets.reduce((acc, bet) => {
            return bet.result ? acc : acc + parseFloat(bet.stake);
        }, 0).toFixed(2);
    };

    const calculateTotalExpenses = (bets) => {
        return bets.reduce((acc, bet) => acc + parseFloat(bet.stake), 0).toFixed(2);
    };

    const getLastTeamPlayed = (bets) => {
        if (!bets.length) return 'N/A';
        const lastBet = bets.reduce((latest, bet) => {
            return bet.bet_id > latest.bet_id ? bet : latest;
        }, bets[0]);
        return lastBet.team_name;
    };

    const getLastBetProfit = (bets) => {
        if (!bets.length) return 'N/A';
        const lastBet = bets.reduce((latest, bet) => {
            return bet.bet_id > latest.bet_id ? bet : latest;
        }, bets[0]);
        return lastBet.profit ? `${parseFloat(lastBet.profit).toFixed(2)} €` : 'N/A';
    };

    const sortSeries = (series) => {
        return series.sort((a, b) => {
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;
            return parseFloat(b.totalExpenses) - parseFloat(a.totalExpenses);
        });
    };

    const applyFilter = () => {
        let filtered = series;

        if (filter === 'open') {
            filtered = filtered.filter(s => !s.is_closed);
        } else if (filter === 'closed') {
            filtered = filtered.filter(s => s.is_closed);
        }

        if (seriesTypeFilter !== 'all') {
            filtered = filtered.filter(s => s.type === seriesTypeFilter);
        }

        if (sportFilter !== 'all') {
            filtered = filtered.filter(s => s.sport === sportFilter);
        }

        if (showZeroEnCours) {
            filtered = filtered.filter(s => parseFloat(s.currentExpenses) === 0);
        }

        if (showNonZeroEnCours) {
            filtered = filtered.filter(s => parseFloat(s.currentExpenses) !== 0);
        }

        if (searchTerm) {
            filtered = filtered.filter(s => s.name && s.name.toLowerCase().includes(searchTerm.toLowerCase()) || (s.bets && s.bets.some(bet => bet.team_name.toLowerCase().includes(searchTerm.toLowerCase()))));
        }

        setFilteredSeries(sortSeries(filtered));
    };

    const addMultipleSeries = async () => {
        const namesArray = Array.from({ length: seriesCount }, (_, i) => `${newSeriesType} ${i + 1}`);
        if (!newSport || !newSeriesType || namesArray.length === 0) {
            alert('Please fill in all fields.');
            return;
        }

        try {
            for (const name of namesArray) {
                await axios.post(`${API_URL}/api/series`, { name: name.trim(), type: newSeriesType, sport: newSport });
            }
            alert(`${namesArray.length} series added successfully!`);
            setPreviewNames([]);
            fetchSeries();
        } catch (error) {
            console.error('Error adding series:', error);
            alert('Failed to add series.');
        }
        setShowModal(false);
    };

    const handleDeleteSeries = async (seriesId) => {
        try {
            await axios.delete(`${API_URL}/api/series/${seriesId}`);
            fetchSeries();
        } catch (error) {
            console.error('Failed to delete series:', error);
            alert('Failed to delete series.');
        }
    };

    const handleZeroEnCoursClick = () => {
        setShowZeroEnCours(true);
        setShowNonZeroEnCours(false);
        localStorage.setItem('showZeroEnCours', 'true');
        localStorage.setItem('showNonZeroEnCours', 'false');
    };

    const handleNonZeroEnCoursClick = () => {
        setShowZeroEnCours(false);
        setShowNonZeroEnCours(true);
        localStorage.setItem('showZeroEnCours', 'false');
        localStorage.setItem('showNonZeroEnCours', 'true');
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        localStorage.setItem('filter', e.target.value);
    };

    const handleSeriesTypeFilterChange = (e) => {
        setSeriesTypeFilter(e.target.value);
        localStorage.setItem('seriesTypeFilter', e.target.value);
    };

    const handleSportFilterChange = (e) => {
        setSportFilter(e.target.value);
        localStorage.setItem('sportFilter', e.target.value);
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        localStorage.setItem('searchTerm', e.target.value);
    };

    const handleEligibleChange = async (seriesId, eligible) => {
        try {
            await axios.put(`${API_URL}/api/series/${seriesId}/eligibility`, { eligible });
            fetchSeries();
        } catch (error) {
            console.error('Failed to update series eligibility:', error);
            alert('Failed to update series eligibility.');
        }
    };

    // Handle editing series name
    const handleEditSeriesName = (seriesId, currentName) => {
        setEditingSeriesId(seriesId);
        setEditingSeriesName(currentName);
    };

    const handleSaveSeriesName = async (seriesId) => {
        try {
            await axios.put(`${API_URL}/api/series/${seriesId}/name`, { name: editingSeriesName });
            fetchSeries(); // Refresh the series list
            setEditingSeriesId(null); // Exit edit mode
        } catch (error) {
            console.error('Failed to update series name:', error);
            alert('Failed to update series name.');
        }
    };

    const generatePreview = () => {
        const namesArray = Array.from({ length: seriesCount }, (_, i) => `${newSeriesType} ${i + 1}`);
        setPreviewNames(namesArray);
    };

    return (
        <div>
            <h1>Series List</h1>
            <div className="center-button">
                <Button variant="primary" onClick={() => setShowModal(true)}>Add New Series</Button>
            </div>
            <div className="filter-container">
                <div className="filter-group">
                    <select value={filter} onChange={handleFilterChange}>
                        <option value="all">Filter: All</option>
                        <option value="open">Filter: Open</option>
                        <option value="closed">Filter: Closed</option>
                    </select>
                    <select value={seriesTypeFilter} onChange={handleSeriesTypeFilterChange}>
                        <option value="all">Series Type: All</option>
                        <option value="équipe">Équipe</option>
                        <option value="nombre de tirs - match">Nombre de tirs - Match</option>
                        <option value="nombre de tirs - équipe">Nombre de tirs - Équipe</option>
                        <option value="nombre de tirs cadrés - match">Nombre de tirs cadrés - Match</option>
                        <option value="nombre de tirs cadrés - équipe">Nombre de tirs cadrés - Équipe</option>
                        <option value="nombre de sets">Nombre de sets</option>
                        <option value="nombre de jeux">Nombre de jeux</option>
                        <option value="nombre d'aces">Nombre d'aces</option>
                    </select>
                    <select value={sportFilter} onChange={handleSportFilterChange}>
                        <option value="all">Sport: All</option>
                        <option value="foot">Foot</option>
                        <option value="tennis">Tennis</option>
                        <option value="basket">Basket</option>
                    </select>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        placeholder="Search Series"
                        style={{ marginLeft: '10px' }}
                    />
                    <Button
                        variant={showZeroEnCours ? "success" : "outline-success"}
                        onClick={handleZeroEnCoursClick}
                    >
                        En-cours à 0€
                    </Button>
                    <Button
                        variant={showNonZeroEnCours ? "success" : "outline-success"}
                        onClick={handleNonZeroEnCoursClick}
                    >
                        En-cours ≠ 0€
                    </Button>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Series</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="newSport">
                            <Form.Label>Sport</Form.Label>
                            <Form.Control
                                as="select"
                                value={newSport}
                                onChange={(e) => setNewSport(e.target.value)}
                            >
                                <option value="">Select Sport</option>
                                <option value="foot">Foot</option>
                                <option value="tennis">Tennis</option>
                                <option value="basket">Basket</option>
                            </Form.Control>
                        </Form.Group>

                        {newSport && (
                            <>
                                <Form.Group controlId="newSeriesType">
                                    <Form.Label>Series Type</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={newSeriesType}
                                        onChange={(e) => setNewSeriesType(e.target.value)}
                                    >
                                        <option value="">Select Type</option>
                                        <option value="équipe">Équipe</option>
                                        <option value="nombre de tirs - match">Nombre de tirs - Match</option>
                                        <option value="nombre de tirs - équipe">Nombre de tirs - Équipe</option>
                                        <option value="nombre de tirs cadrés - match">Nombre de tirs cadrés - Match</option>
                                        <option value="nombre de tirs cadrés - équipe">Nombre de tirs cadrés - Équipe</option>
                                        <option value="nombre de sets">Nombre de sets</option>
                                        <option value="nombre de jeux">Nombre de jeux</option>
                                        <option value="nombre d'aces">Nombre d'aces</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="seriesCount">
                                    <Form.Label>Number of Series</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={seriesCount}
                                        onChange={(e) => setSeriesCount(parseInt(e.target.value, 10))}
                                        min="1"
                                        placeholder="Number of series to create"
                                    />
                                </Form.Group>

                                <Form.Group controlId="seriesPreview">
                                    <Form.Label>Preview</Form.Label>
                                    <ul>
                                        {previewNames.map((name, index) => (
                                            <li key={index}>{name}</li>
                                        ))}
                                    </ul>
                                </Form.Group>
                            </>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" onClick={addMultipleSeries}>Add Series</Button>
                </Modal.Footer>
            </Modal>

            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Sport</th>
                            <th>Series ID</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Last Team Played</th>
                            <th>Profit du Dernier Pari</th> {/* Nouvelle colonne */}
                            <th>En-cours pas encore joué</th>
                            <th>Dépenses totales</th>
                            <th>Eligible</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSeries.map(s => (
                            <React.Fragment key={s.series_id}>
                                <tr>
                                    <td>{s.sport}</td>
                                    <td>{s.series_id}</td>
                                    <td>
                                        {editingSeriesId === s.series_id ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    value={editingSeriesName}
                                                    onChange={(e) => setEditingSeriesName(e.target.value)}
                                                    onBlur={() => handleSaveSeriesName(s.series_id)}
                                                    onKeyPress={(e) => e.key === 'Enter' && handleSaveSeriesName(s.series_id)}
                                                />
                                            </div>
                                        ) : (
                                            <div>
                                                <Link to={`/series/${s.series_id}`} className="series-link">
                                                    {s.name || 'undefined'}
                                                </Link>
                                                <FaEdit
                                                    className="edit-icon"
                                                    onClick={() => handleEditSeriesName(s.series_id, s.name)}
                                                />
                                            </div>
                                        )}
                                    </td>
                                    <td>{s.is_closed ? 'Closed' : 'Open'}</td>
                                    <td>{s.type}</td>
                                    <td>{s.lastTeamPlayed}</td>
                                    <td>{s.lastBetProfit}</td> {/* Nouvelle colonne */}
                                    <td>{s.currentExpenses} €</td>
                                    <td>{s.totalExpenses} €</td>
                                    <td>
                                        <input 
                                            type="checkbox" 
                                            checked={s.eligible} 
                                            onChange={(e) => handleEligibleChange(s.series_id, e.target.checked)} 
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => handleDeleteSeries(s.series_id)} style={{ marginLeft: '10px' }}>Delete</button>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SeriesManager;
